/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import EnvioRealizado from "@/images/img/sucess-img.png"

import { 
  contentPage,
}  from '../assets/styles/Other.styles'

const EssencialDiferencial = () => {
  return (
    <Fragment>
      <section css={contentPage}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="text-center"><img src={EnvioRealizado} alt='/' /></div>
              <h2>sua <span>mensagem</span> foi enviada com <span>êxito</span></h2>
              <p>Nosso time comercial entrará em contato com você</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default EssencialDiferencial;
